// Vendor
window.$ = window.jQuery = require('jquery')
window.select2 = require('select2')
window.autosize = require('autosize').default
require('bootstrap')
require('../shared/modules/bootstrap-colorpicker/dist/js/bootstrap-colorpicker')
require('jquery.scrollbar')
require('jquery-scroll-lock')
require('nouislider')

// Custom
require('./scripts/theme.min')
require('./scripts/bulk')
require('./scripts/views.edit')
require('./scripts/users.edit')
require('./scripts/projects.edit')
require('./scripts/portation')
require('./scripts/app')

// Styling
require('./index.studio3d.scss')